<template>
  <div class="home">
    <transition name="clipCircleMb">
      <Welcome v-if="welcome" @skip="skip"></Welcome>
    </transition>
    <HomeNav v-if="!welcome"></HomeNav>
  </div>
</template>

<script>
import Welcome from "./Welcome.vue";
import HomeNav from "./HomeNav.vue";

export default {
  components: {
    Welcome,
    HomeNav,
  },
  data() {
    return {
      welcome: true,
    };
  },
  methods: {
    skip() {
      this.welcome = false;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  overflow: hidden;
}
.clipCircleMb-leave-active {
  animation: clipCircleIn 1.2s reverse;
}
@keyframes clipCircleIn {
  0% {
    clip-path: circle(0 at 50% 50%);
  }
  100% {
    clip-path: circle(140vw at 50% 50%);
  }
}
</style>

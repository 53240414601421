<template>
  <div :class="['welcome', animate && 'animate']">
    <div class="leafs">
      <img class="leaf-1" src="./imgs/leaf-1.png" alt="" />
      <img class="leaf-2" src="./imgs/leaf-2.png" alt="" />
    </div>
    <div class="line">
      <div class="line-1"></div>
      <div class="line-2">
        <div class="text">产教一体</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  data() {
    return {
      animate: false,
    };
  },
  methods: {
    handleScroll(e) {
      if (this.active == 0) {
        return;
      }
      let direction = e.deltaY > 0 ? "down" : "up";
      if (direction == "down") {
        this.active++;
      } else {
        this.active--;
      }
    },
    _debounce(func, wait, immediate) {
      var timeout;
      return function () {
        var context = this,
          args = arguments;
        var later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    startAnimate() {
      setTimeout(() => {
        this.animate = true;
        setTimeout(() => {
          this.$emit("skip");
        }, 2000);
      }, 500);
    },
  },
  mounted() {
    this.startAnimate();
  },
};
</script>
<style scoped lang="scss">
.welcome {
  height: 100%;
  overflow: hidden;
  background: url("../../../assets/img/bg.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .leafs {
    width: rem(813);
    height: rem(347);
    position: relative;
    .leaf-1 {
      width: rem(665);
      position: absolute;
      left: rem(74);
      top: -400%;
      z-index: 2;
    }
    .leaf-2 {
      width: 100%;
      position: absolute;
      left: 0;
      z-index: 1;
      bottom: -400%;
    }
  }
  .line {
    &-1 {
      width: rem(120);
      height: 1px;
      background: #57bd16;
      position: absolute;
      left: -100%;
    }
    &-2 {
      width: rem(150);
      height: 1px;
      background: #57bd16;
      position: absolute;
      right: -100%;
      z-index: 3;
      .text {
        color: rgba(255, 255, 255, 0.89);
        font-size: 12px;
        transform: translate(50px, -100%) skewX(-30deg);
        opacity: 0;
        width: rem(390);
        left: rem(-250);
        position: absolute;
        top: rem(-40);
      }
    }
  }
  &.animate {
    .leafs {
      .leaf-1 {
        top: rem(35);
        transition: all 0.5s 1s;
      }
      .leaf-2 {
        bottom: 0;
        transition: all 0.7s 0.8s;
      }
    }
    .line {
      &-1 {
        left: 0;
        transition: all 0.8s;
      }
      &-2 {
        right: 0;
        transition: all 0.8s;
        .text {
          transform: translate(0px, -100%) skewX(0deg);
          opacity: 1;
          transition: all 1s 1.7s;
        }
      }
    }
  }
}
</style>

<template>
  <div
    :class="['home-nav', animate && 'animate', end && 'end']"
    @touchstart="touchstart"
    @touchend="touchend"
    @touchmove="touchmove"
  >
    <div
      class="wood main"
      @click="linkUrl('NewWood')"
      :style="{
        height: percentage + '%',
        opacity: percentage / 50,
      }"
    >
      <img src="./imgs/leaf-4.png" class="logo" alt="" />
      <img src="./imgs/pictures.png" class="pictures" alt="" />
      <div class="intro">
        <p>团队人数<span class="bold">100+</span>,均来自行业</p>
        <p>一线公司,美术视觉设计经验</p>
        <p>丰富,提供全流程设计、</p>
        <p>人才管理等服务。</p>
      </div>
    </div>
    <div
      class="leafs main"
      @click="linkUrl('Leaf')"
      :style="{
        height: 100 - percentage + '%',
        opacity: (100 - percentage) / 50,
      }"
    >
      <img src="./imgs/leaf-5.png" alt="" class="leaf" />
      <img src="./imgs/card.png" alt="" class="card" />
      <img src="./imgs/yezi.png" alt="" class="yezi" />
      <div class="intro">
        <p><span class="bold">产教一体 </span>综合团队</p>
        <p>提供游戏职业教育、</p>
        <p>校企合作服务</p>
        <p>学员 <span class="green">12</span> 万</p>
        <p><span class="green">100%</span> 推荐就业</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      animate: false,
      percentage: 50,
      startX: 0,
      startY: 0,
      flag: false,
      end: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.animate = true;
    }, 1300);
  },
  methods: {
    linkUrl(name) {
      this.$router.push({ name });
    },
    touchstart(event) {
      this.startX = event.touches[0].pageX;
      this.startY = event.touches[0].pageY;
    },
    touchend(event) {
      if (this.percentage > 60 && this.percentage <= 100) {
        this.percentage = 100;
      } else if (this.percentage <= 40 && this.percentage >= 0) {
        this.percentage = 0;
      } else {
        this.percentage = 50;
      }
      this.end = true;
      setTimeout(() => {
        this.end = false;
        this.percentage == 0 && this.$router.push({ name: "Leaf" });
        this.percentage == 100 && this.$router.push({ name: "NewWood" });
      }, 200);
    },
    touchmove(event) {
      var spanY = event.changedTouches[0].pageY - this.startY;
      if (this.percentage >= 100) {
        this.percentage = 100;
        return;
      }
      if (this.percentage <= 0) {
        this.percentage = 0;
        return;
      }
      if (spanY > 0) {
        this.percentage += 0.5;
      } else if (spanY < -30) {
        this.percentage -= 0.5;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-nav {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .main {
    position: relative;
    // transition: all 0.5s;
  }
  .wood {
    // background: url("./imgs/bg-2.png") no-repeat;
    background-size: 250%;
    background-position: rem(-200);
    .logo {
      width: rem(699);
      position: absolute;
      left: rem(19);
      top: rem(43);
      transform: translateY(-150%);
    }
    .pictures {
      width: rem(896 * 0.95);
      position: absolute;
      right: rem(-200);
      bottom: rem(0);
      transform: translateX(100%);
    }
    .intro {
      position: absolute;
      left: rem(50);
      bottom: rem(210);
      p {
        color: #353535;
        font-size: 12px;
        font-weight: bold;
        line-height: rem(70);
        transform: translateY(20px);
        opacity: 0;
      }
    }
  }
  .leafs {
    background: rgba(0, 0, 0);
    .leaf {
      z-index: 1;
      width: rem(931);
      position: absolute;
      right: rem(-350);
      top: rem(35);
      transform-origin: right bottom;
      transform: rotate(60deg);
    }
    .card {
      z-index: 2;
      width: rem(924);
      position: absolute;
      left: rem(-210);
      top: rem(84);
      transform-origin: left bottom;
      transform: rotate(-60deg);
    }
    .yezi {
      z-index: 3;
      width: rem(305);
      position: absolute;
      left: rem(85);
      top: rem(593);
      opacity: 0;
      transform: scale(1.5);
    }
    .intro {
      z-index: 4;
      position: absolute;
      right: rem(-50);
      bottom: rem(90);
      width: rem(450);
      transform: rotate(-5deg) scale(0.85);
      p {
        color: #fff;
        font-size: 12px;
        line-height: 23px;
        transform: translateY(20px);
        opacity: 0;
        .bold {
          font-size: 16px;
          font-weight: bold;
          margin-right: 4px;
        }
        &:nth-of-type(1) {
          position: relative;
          padding-bottom: rem(10);
          &::after {
            content: "";
            height: 1px;
            width: 0;
            background: #8a53ec;
            display: inline-block;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
        .bold {
          font-size: 16px;
          font-weight: 500;
          padding-right: 5px;
        }
        .green {
          font-size: 16px;
          color: #58bc17;
          font-weight: bold;
        }
      }
    }
  }
  &.animate {
    .wood {
      .logo {
        transition: transform 0.3s;
        transform: translateY(0);
      }
      .pictures {
        transition: transform 0.3s;
        transform: translateX(0);
      }
      .intro {
        p {
          transition: transform 0.6s, opacity 0.6s;
          transform: translateY(0);
          opacity: 1;
          &:nth-of-type(1) {
            transition-delay: 0.2s;
          }
          &:nth-of-type(2) {
            transition-delay: 0.4s;
          }
          &:nth-of-type(3) {
            transition-delay: 0.6s;
          }
          &:nth-of-type(4) {
            transition-delay: 0.8s;
          }
        }
      }
    }
    .leafs {
      .leaf {
        transition: transform 0.3s;
        transform: rotate(0);
      }
      .card {
        transition: transform 0.3s;
        transform: rotate(0);
      }
      .yezi {
        opacity: 1;
        transform: scale(1);
        transition: transform 0.3s 0.2s, opacity 0.3s 0.2s;
      }
      .intro {
        p {
          transition: transform 0.6s, opacity 0.6s;
          transform: translateY(0);
          opacity: 1;
          &:nth-of-type(1) {
            transition-delay: 0.2s;
            &::after {
              width: 100%;
              transition: width 0.3s 0.5s;
            }
          }
          &:nth-of-type(2) {
            transition-delay: 0.4s;
          }
          &:nth-of-type(3) {
            transition-delay: 0.6s;
          }
          &:nth-of-type(4) {
            transition-delay: 0.8s;
          }
          &:nth-of-type(5) {
            transition-delay: 1s;
          }
        }
      }
    }
  }
}
.end {
  .main {
    transition: height 0.3s;
  }
}
</style>
